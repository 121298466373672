class TableOfContents extends HTMLElement {
  connectedCallback() {
    this.render();
    this.observeHeadings();
  }

  render() {
    const headings = Array.from(document.querySelectorAll('h2'))
      .filter(heading => heading.id);

    if (headings.length < 2) {
      this.style.display = 'none';
      return;
    }

    const toc = this.generateTOC(headings);
    
    this.innerHTML = `
      <h3>On this page</h3>
      <div>${toc.outerHTML}</div>
    `;
  }

  generateTOC(headings) {
    const toc = document.createElement('ul');
    const stack = [{ level: 1, element: toc }];

    headings.forEach(heading => {
      const level = parseInt(heading.tagName.charAt(1));
      const listItem = document.createElement('li');
      const link = document.createElement('a');
      link.textContent = heading.textContent;
      link.href = `#${heading.id}`;
      listItem.appendChild(link);

      while (level <= stack[stack.length - 1].level) {
        stack.pop();
      }

      if (level > stack[stack.length - 1].level) {
        const newList = document.createElement('ul');
        stack[stack.length - 1].element.appendChild(newList);
        stack.push({ level, element: newList });
      }

      stack[stack.length - 1].element.appendChild(listItem);
    });

    return toc;
  }

  observeHeadings() {
    const headings = Array.from(document.querySelectorAll('h2'))
      .filter(heading => heading.id);

    let isScrolling = false;
    let clickedLink = null;

    const observer = new IntersectionObserver(
      (entries) => {
        if (!isScrolling && !clickedLink) {
          const visibleHeadings = entries.filter(entry => entry.isIntersecting);
          if (visibleHeadings.length > 0) {
            const firstVisibleHeading = visibleHeadings[0].target;
            this.highlightTOCItem(firstVisibleHeading);
          }
        }
      },
      { rootMargin: '0px 0px 0px 0px' }
    );

    headings.forEach(heading => observer.observe(heading));

    // Add click event listeners to TOC links
    const tocLinks = this.querySelectorAll('a');
    tocLinks.forEach(link => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const targetId = link.getAttribute('href').slice(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          clickedLink = link;
          this.highlightTOCItem(targetElement);
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    });

    // Add scroll event listener
    window.addEventListener('scroll', () => {
      if (clickedLink) {
        isScrolling = true;
        clearTimeout(window.scrollTimeout);
        window.scrollTimeout = setTimeout(() => {
          isScrolling = false;
          clickedLink = null;
        }, 100);
      }
    }, { passive: true });
  }

  highlightTOCItem(heading) {
    const links = this.querySelectorAll('a');
    links.forEach(link => link.classList.remove('active'));

    const headingId = heading.id;
    const correspondingLink = this.querySelector(`a[href="#${headingId}"]`);
    if (correspondingLink) {
      correspondingLink.classList.add('active');
    }
  }
}

customElements.define('table-of-contents', TableOfContents);
